<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a" @click="$router.back()"
            >培训档案</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">人脸审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="faceCheck">
          <span style="margin: 0 20px 0 0"
            >学员姓名：{{ searchData.userName }}</span
          >
          <span style="margin: 0 20px">身份证号：{{ searchData.idCard }}</span>
          <span style="margin: 0 20px 0 0"
            >班级名称：{{ searchData.projectName }}</span
          >
          <span style="margin: 0 20px"
            >需重学课件：{{ searchData.unqualifiedNum }}节</span
          >
          <span style="margin: 0 20px 0 0" v-if="searchData.remedyState != 0"
            >补学状态：{{
              searchData.remedyState == 2 ? "已完成" : "进行中"
            }}</span
          >
        </div>
        <div
          class="faceCheck"
          style="margin: 15px 0; justify-content: flex-end"
        >
          <el-select
            v-model="searchData.remedyType"
            placeholder="请选择"
            size="mini"
            @change="getDataFace(1)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="df" style="margin-left: 20px">
            <el-button
              type="primary"
              size="mini"
              class="bgc-bv"
              round
              v-if="
                Number(unqualifiedNum) > 0 ||
                Number(searchData.unqualifiedNum) == 0
              "
              @click="sure"
              >确认补学</el-button
            >
            <el-button
              v-else
              type="primary"
              size="mini"
              style="
                background: #e7e7e7;
                border: 1px solid #bfbfbf;
                color: #333;
              "
              round
              disabled
              >已确认补学</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              size="small"
              tooltip-effect="dark"
              :height="tableHeight"
              :header-cell-style="tableHeader"
              style="padding-right: 10px"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="center"
                show-overflow-tooltip
                prop="courseName"
                width="180"
              />
              <el-table-column
                label="课件名称"
                align="center"
                show-overflow-tooltip
                prop="catalogName"
                width="180"
              />
              <el-table-column
                label="认证照片"
                align="center"
                prop="faceInfoBOS"
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.faceInfoBOS && scope.row.faceInfoBOS.length < 0
                    "
                    >--</span
                  >
                  <div v-else class="checkStyle">
                    <div
                      v-for="(item, index) in scope.row.faceInfoBOS"
                      :key="index"
                      class="checkStyleBox"
                    >
                      <!-- dealComplete 没有 未标记，   0 是标记了，1是确认补学了(不允许修改)，2是补学完成  -->
                      <el-checkbox
                        v-model="scope.row.selected"
                        :disabled="
                          scope.row.notComplete ||
                          scope.row.dealComplete == '1' ||
                          scope.row.dealComplete == '2' ||
                          projectSourceFace
                        "
                        @change="
                          getState(
                            scope.row.selected,
                            item.faceId,
                            item.studyId,
                            scope.row
                          )
                        "
                      ></el-checkbox>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="课件未学完不能选择"
                        placement="top"
                        v-if="scope.row.notComplete"
                      >
                        <img
                          style="height: 100px; margin: 0 5px"
                          :src="item.httpApproveFace"
                          alt
                        />
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="已补学完课件不能再次选择"
                        placement="top"
                        v-else-if="scope.row.dealComplete == '2'"
                      >
                        <img
                          style="height: 100px; margin: 0 5px"
                          :src="item.httpApproveFace"
                          alt
                        />
                      </el-tooltip>
                      <!-- <div
                        class="modelBox"
                        v-if="scope.row.notComplete||scope.row.dealComplete=='1'||scope.row.dealComplete=='2'"
                      >
                        <div class="tipMsg">
                          <img src="../../assets/stateNo.png" alt v-if="scope.row.notComplete" />
                          <img src="../../assets/stateYes.png" alt v-else-if="scope.row.dealComplete=='2'" />
                          <img src="../../assets/stateGo.png" alt v-else-if="scope.row.dealComplete=='1'" />
                        </div>
                      </div> -->
                      <!-- <img v-else style="height:100px" :src="item.httpApproveFace" alt /> -->
                      <el-image
                        v-else
                        style="height: 100px"
                        :src="item.httpApproveFace"
                        :preview-src-list="[item.httpOriginalFace]"
                      >
                      </el-image>
                      <!-- <el-checkbox
                        v-model="item.checked"
                        :disabled="item.disabled"
                        @change="getState(scope.row.imageUrl,item.id)"
                        v-if="index<scope.row.showNum"
                      ></el-checkbox>
                      <img style="width:90px;margin:0 5px" :src="item.url" alt v-if="index<scope.row.showNum"/>-->
                    </div>
                    <!-- <span class="getMuch" @click="getMuch(scope.row)" v-if="scope.row.imageUrl.length>scope.row.showNum">加载更多>></span> -->
                  </div>
                </template>
              </el-table-column>

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getDataFace"
        />
      </div>
    </div>
    <el-dialog
      title="确认补学"
      :visible.sync="docsDialogVisible"
      width="900px"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div>
        <div class="ovy-a">
          <div style="display: flex; justify-content: space-between">
            <span>学员姓名：{{ searchData.userName }}</span>
            <span>身份证号：{{ searchData.idCard }}</span>
            <span>班级名称：{{ searchData.projectName }}</span>
            <span>需补学课件：{{ searchData.unqualifiedNum }}节</span>
          </div>

          <el-table
            :data="studyData"
            border
            style="width: 100%; margin-top: 20px"
            height="500"
          >
            <el-table-column
              prop="projectName"
              label="新增补学课件"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button @click="closeDocsDialog">取消</el-button>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "trainingManagement/faceCheck",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      checkList: [],
      docsDialogVisible: false,
      unqualifiedNum: 0, //判断是否增加课件  存储未确认的课件数
      searchData: {
        projectId: "", //班级Id
        userId: "", //学员ID
        userName: "", //学员名称
        idCard: "", //身份证号
        projectName: "", //班级名称
        unqualifiedNum: 0, //已确定+未确定
        remedyType: "", //选择条件
        remedyState: "", //补学状态
      },
      options: [],
      tableData: [],
      studyData: [], //新增补学课件
      projectSourceFace: false,
    };
  },
  created() {
    this.searchData.projectId = this.$route.query.projectId;
    this.searchData.userId = this.$route.query.userId;
  },
  watch: {},
  computed: {},
  mounted() {
    this.getBaseInfo();
    this.getDataFace();
    this.getTableHeight();
    this.getOptions();
    this.projectSourceFace =
      sessionStorage.getItem("projectSourceFace") == "30" ? true : false;
  },
  methods: {
    getOptions() {
      const classStatusList = this.$setDictionary("REMEDYTYPE", "list");
      const list = [];
      list.push({
        value: "",
        label: "全部",
      });
      for (const key in classStatusList) {
        list.push({
          value: key,
          label: classStatusList[key],
        });
      }
      this.options = list;
    },
    getDataFace(pageNum = 1) {
      const params = {
        projectId: this.searchData.projectId,
        userId: this.searchData.userId,
        pageSize: this.pageSize,
      };
      if (this.searchData.remedyType) {
        params.remedyType = this.searchData.remedyType;
      }

      this.doFetch({
        url: "/run/projectCourse/queryUserReviewList",
        params,
        pageNum,
      });
    },
    //确认按钮
    sure() {
      if (this.projectSourceFace) {
        this.$message({
          type: "warning",
          message: "点名机制班级不支持补学",
        });
        return;
      }
      if (Number(this.unqualifiedNum) == 0) {
        this.$message({
          type: "warning",
          message: "请选择不合规的照片",
        });
        return;
      }
      this.$post("/biz/user/face/mark/queryKpointNameList", {
        projectId: this.searchData.projectId,
        userId: this.searchData.userId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.studyData = [];

            for (let item in res.data) {
              let obj = { projectName: "" };
              obj.projectName = res.data[item];
              this.studyData.push(obj);
            }
            console.log(this.studyData);
            this.docsDialogVisible = true;
          }
        })
        .catch(() => {});
    },
    //弹框确定按钮
    confirmDocsDialog() {
      this.$post("/run/projectCourse/confirmRemedy", {
        projectId: this.searchData.projectId,
        userId: this.searchData.userId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1000,
            });
            this.getDataFace(-1);
            this.getBaseInfo();
          }
        })
        .catch(() => {
          this.getDataFace(-1);
          this.getBaseInfo();
        });
      this.docsDialogVisible = false;
    },
    //弹框取消按钮
    closeDocsDialog() {
      this.docsDialogVisible = false;
    },
    //存储或者删除
    getState(selected, faceId, studyId, row) {
      if (selected) {
        this.searchData.unqualifiedNum =
          Number(this.searchData.unqualifiedNum) + 1;
        this.unqualifiedNum += 1;
        row.faceId = faceId;
      } else {
        this.searchData.unqualifiedNum =
          Number(this.searchData.unqualifiedNum) - 1;
        this.unqualifiedNum -= 1;
      }

      this.$post("/run/projectCourse/insertOrDelete", {
        faceId: selected ? faceId : row.faceId,
        store: selected, //true存，false 删除
      })
        .then((res) => {
          if (res.status == 0) {
            // this.$message({
            //   message: "操作成功",
            //   type: "success",
            //   duration: 1000
            // });
          }
        })
        .catch(() => {
          this.getDataFace(-1);
          this.getBaseInfo();
        });
    },
    getMuch(row) {
      row.showNum += 1;
    },
    //获取基本信息
    getBaseInfo() {
      let params = {
        projectId: this.searchData.projectId,
        userId: this.searchData.userId,
      };
      this.$post("/biz/projectUser/queryProjectNameAndUserName", params).then(
        (res) => {
          if (res.status == 0) {
            this.searchData.idCard = res.data.idCard;
            this.searchData.projectName = res.data.projectName;
            this.searchData.unqualifiedNum =
              Number(res.data.unqualifiedNum) +
              Number(res.data.unqualifiedMustNum);
            this.searchData.userName = res.data.userName;
            this.unqualifiedNum = res.data.unqualifiedNum;
            this.searchData.remedyState = res.data.remedyState;
          }
        }
      );
    },
    getTableHeight(opDom = true, page = true) {},
    handleListDataBack(tableData) {
      this.tableData = tableData;
      this.oneKeyGet(tableData);
    },
    async oneKeyGet(tableData) {
      for (let i in tableData) {
        let params = {
          projectCourseId: tableData[i].projectCourseId,
          projectId: this.searchData.projectId,
          userId: this.searchData.userId,
          kpointId: tableData[i].kpointId,
        };
        await this.$post(
          "/run/projectCourse/queryUserReviewImageList",
          params,
          3000,
          false
        ).then((res) => {
          if (res.status == 0) {
            this.$set(this.tableData[i], "faceInfoBOS", [...res.data]);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.faceCheck {
  display: flex;
  align-items: center;
  padding: 0 0.675rem;
}
.checkStyle {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end;
  overflow: auto;
}
.checkStyleBox {
  position: relative;
  margin: 0 5px;
  .el-checkbox {
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 22;
  }
  .modelBox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    .tipMsg {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      height: 15px;
      // background-color: #5c6be8;
      color: #fff;
      font-size: 12px;
      img {
        // margin-top: 1px;
      }
    }
  }
}
.getMuch {
  color: #6676ff;
  cursor: pointer;
}
.el-table--border {
  border-bottom: 1px solid #ebeef5;
}
</style>

